var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-template',{attrs:{"menuName":_vm.$t('APMA.APMA'),"newButton":_vm.role_type == 'admin' || _vm.role_type == 'superadmin',"backButton":false},on:{"moveToForm":function($event){return _vm.moveToForm()}},scopedSlots:_vm._u([{key:"table-header",fn:function(){return [_c('span',{class:("s-mb-30 " + (_vm.isMobile ? 's-fullWidth' : 's-flex s-mt--65'))},[_c('Input',{staticClass:"s-ml-auto",attrs:{"label":"","placeholder":_vm.$t('general.search')},on:{"keyup":[function($event){return _vm.setTime()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.clearTime()}],"keydown":[function($event){return _vm.clearTime()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.setTime()}],"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getData()}},model:{value:(_vm.request.search),callback:function ($$v) {_vm.$set(_vm.request, "search", $$v)},expression:"request.search"}})],1)]},proxy:true},{key:"table",fn:function(){return [_c('TableCustom',{attrs:{"columns":_vm.columns,"request":_vm.request,"fetchData":_vm.fetchData,"data":_vm.rows},on:{"setSortBy":_vm.setSortBy,"setShow":_vm.setShow,"getData":_vm.getData,"mouseleave":function($event){_vm.popup = null},"togglePopup":_vm.togglePopup},scopedSlots:_vm._u([{key:"index",fn:function(props){return [_vm._v(" "+_vm._s(_vm.curNumber(props.index))+" ")]}},{key:"cycle_phase_name",fn:function(props){return [_c('div',{class:("\n            s-p-10\n            " + (props.row.cycle_phase_name == 'Risk Identification'
                ? 's-bg-risk-ident'
                : props.row.cycle_phase_name ==
                  'Risk Identification and Reduction'
                ? 's-bg-risk-ident-reduct'
                : props.row.cycle_phase_name == 'Risk Reduction'
                ? 's-bg-risk-reduct'
                : props.row.cycle_phase_name == 'Preparedness and Response'
                ? 's-bg-prep-response'
                : props.row.cycle_phase_name ==
                  'Risk Identification and Research'
                ? 's-bg-primary s-text-white'
                : props.row.cycle_phase_name == 'Recovery and Rehabilitation'
                ? 's-bg-error s-text-white'
                : 's-bg-white'))},[_vm._v(" "+_vm._s(props.row.cycle_phase_name)+" ")])]}},{key:"action",fn:function(props){return [_c('div',{staticClass:"r-menu"},[_c('img',{staticClass:"r-isClickAble",attrs:{"src":require('@/assets/icons/others/more.svg')}}),(_vm.popup == _vm.curNumber(props.index))?_c('div',{staticClass:"r-menu-items",on:{"mouseleave":function($event){_vm.popup = -1}}},[(_vm.role_type == 'superadmin' || _vm.role_type == 'admin')?_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.setDetail(props.row, 'Detail APMA Assign Task')}}},[_vm._v(" Detail ")]):_vm._e(),(
                  _vm.role_type == 'stakeholder' && props.row.status == 'Finish'
                )?_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.setPublish(props.row, 'showModalPublish')}}},[_vm._v(" "+_vm._s(_vm.$t("APMA.publish"))+" ")]):_vm._e(),(
                  (_vm.role_type == 'superadmin' || _vm.role_type == 'admin') &&
                  props.row.status != 'Finish'
                )?_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.setUpdate(props.row)}}},[_vm._v(" "+_vm._s(_vm.$t("general.update"))+" ")]):_vm._e(),(_vm.role_type != 'superadmin' && _vm.role_type != 'admin')?_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.setDetail(props.row, 'Detail APMA')}}},[_vm._v(" Detail ")]):_vm._e(),_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.setFile(props.row)}}},[_vm._v(" "+_vm._s(_vm.$t("APMA.uploadProgressFile"))+" ")]),(
                  _vm.role_type != 'superadmin' &&
                  _vm.role_type != 'admin' &&
                  _vm.role_type != 'expert'
                )?_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.openDialog('showModalContact', props.row)}}},[_vm._v(" "+_vm._s(_vm.$t("APMA.expertContact"))+" ")]):_vm._e(),(props.row.status !== 'Finish')?_c('div',{staticClass:"r-menu-item s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.setForum(props.row)}}},[_vm._v(" Forum ")]):_vm._e(),(
                  (_vm.role_type == 'superadmin' || _vm.role_type == 'admin') &&
                  props.row.status == 'New'
                )?_c('div',{staticClass:"r-menu-item s-text-red s-center-flex",on:{"click":function($event){$event.preventDefault();return _vm.openDialog('showModalDelete', props.row)}}},[_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" ")]):_vm._e()]):_vm._e()])]}}])})]},proxy:true}])}),(_vm.modal == 'showModalDelete')?_c('Modal',{attrs:{"title":_vm.$t('general.warning')},scopedSlots:_vm._u([{key:"modalContent",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t("general.modalDelete")))]),_c('div',{staticClass:"s-mtb-20 s-flex"},[_c('div',{staticClass:"s-ml-auto"},[_c('Button',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.onDelete.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" ")])],1)])]},proxy:true}],null,false,522104947)}):_vm._e(),(_vm.modal == 'showModalContact')?_c('Modal',{attrs:{"title":_vm.$t('APMA.expertContact')},scopedSlots:_vm._u([{key:"modalContent",fn:function(){return [(_vm.consultant.length < 0)?_c('div',{staticClass:"s-mtb-30"},_vm._l((_vm.consultant),function(e,i){return _c('table',{key:("expert-" + i)},[_c('tr',[_c('td',{staticClass:"s-wd-150-min s-input-label"},[_vm._v(_vm._s(e.name))]),_c('td',[_vm._v(_vm._s(e.phone_number))]),_c('td',[_vm._v(_vm._s(e.email))])])])}),0):_c('div',{staticClass:"s-mtb-30",staticStyle:{"text-align":"center"}},[_vm._v(" Empty ")])]},proxy:true}],null,false,3394215882)}):_vm._e(),(_vm.modal == 'showModalPublish')?_c('Modal',{attrs:{"title":_vm.$t('APMA.publishActionPlan')},scopedSlots:_vm._u([{key:"modalContent",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t("APMA.modalPublish")))]),_c('div',{staticClass:"s-mtb-20 s-flex"},[_c('div',{staticClass:"s-ml-auto"},[_c('Button',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.onPublishAPMA.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("APMA.publish"))+" ")])],1)])]},proxy:true}],null,false,1324031150)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }