<template>
  <div>
    <content-template
      :menuName="$t('APMA.APMA')"
      :newButton="role_type == 'admin' || role_type == 'superadmin'"
      @moveToForm="moveToForm()"
      :backButton="false"
    >
      <template #table-header>
        <span
          :class="`s-mb-30 ${isMobile ? 's-fullWidth' : 's-flex s-mt--65'}`"
        >
          <Input
            class="s-ml-auto"
            label=""
            :placeholder="$t('general.search')"
            v-model="request.search"
            @keyup="setTime()"
            @keydown="clearTime()"
            @keydown.tab="setTime()"
            @keyup.tab="clearTime()"
            @keypress.enter="getData()"
          />
        </span>
      </template>

      <template #table>
        <TableCustom
          :columns="columns"
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>

          <template #cycle_phase_name="props">
            <div
              :class="`
              s-p-10
              ${
                props.row.cycle_phase_name == 'Risk Identification'
                  ? 's-bg-risk-ident'
                  : props.row.cycle_phase_name ==
                    'Risk Identification and Reduction'
                  ? 's-bg-risk-ident-reduct'
                  : props.row.cycle_phase_name == 'Risk Reduction'
                  ? 's-bg-risk-reduct'
                  : props.row.cycle_phase_name == 'Preparedness and Response'
                  ? 's-bg-prep-response'
                  : props.row.cycle_phase_name ==
                    'Risk Identification and Research'
                  ? 's-bg-primary s-text-white'
                  : props.row.cycle_phase_name == 'Recovery and Rehabilitation'
                  ? 's-bg-error s-text-white'
                  : 's-bg-white'
              }`"
            >
              {{ props.row.cycle_phase_name }}
            </div>
          </template>

          <template #action="props">
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="
                    setDetail(props.row, 'Detail APMA Assign Task')
                  "
                  v-if="role_type == 'superadmin' || role_type == 'admin'"
                >
                  Detail
                </div>
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setPublish(props.row, 'showModalPublish')"
                  v-if="
                    role_type == 'stakeholder' && props.row.status == 'Finish'
                  "
                >
                  {{ $t("APMA.publish") }}
                </div>
                <div
                  class="r-menu-item s-center-flex"
                  v-if="
                    (role_type == 'superadmin' || role_type == 'admin') &&
                    props.row.status != 'Finish'
                  "
                  @click.prevent="setUpdate(props.row)"
                >
                  {{ $t("general.update") }}
                </div>

                <div
                  v-if="role_type != 'superadmin' && role_type != 'admin'"
                  class="r-menu-item s-center-flex"
                  @click.prevent="setDetail(props.row, 'Detail APMA')"
                >
                  Detail
                </div>
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setFile(props.row)"
                >
                  {{ $t("APMA.uploadProgressFile") }}
                </div>
                <div
                  v-if="
                    role_type != 'superadmin' &&
                    role_type != 'admin' &&
                    role_type != 'expert'
                  "
                  class="r-menu-item s-center-flex"
                  @click.prevent="openDialog('showModalContact', props.row)"
                >
                  {{ $t("APMA.expertContact") }}
                </div>
                <div
                  v-if="props.row.status !== 'Finish'"
                  class="r-menu-item s-center-flex"
                  @click.prevent="setForum(props.row)"
                >
                  Forum
                </div>
                <div
                  class="r-menu-item s-text-red s-center-flex"
                  v-if="
                    (role_type == 'superadmin' || role_type == 'admin') &&
                    props.row.status == 'New'
                  "
                  @click.prevent="openDialog('showModalDelete', props.row)"
                >
                  {{ $t("general.delete") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>
      </template>
    </content-template>

    <Modal v-if="modal == 'showModalDelete'" :title="$t('general.warning')">
      <template #modalContent>
        <p>{{ $t("general.modalDelete") }}</p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click.prevent="onDelete">
              {{ $t("general.delete") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Modal Open Expert Contact -->
    <Modal v-if="modal == 'showModalContact'" :title="$t('APMA.expertContact')">
      <template #modalContent>
        <div class="s-mtb-30" v-if="consultant.length < 0">
          <table v-for="(e, i) in consultant" :key="`expert-${i}`">
            <tr>
              <td class="s-wd-150-min s-input-label">{{ e.name }}</td>
              <td>{{ e.phone_number }}</td>
              <td>{{ e.email }}</td>
            </tr>
          </table>
        </div>
        <div v-else class="s-mtb-30" style="text-align:center">
          Empty
        </div>
      </template>
    </Modal>

    <!-- Modal Publish-->
    <Modal
      v-if="modal == 'showModalPublish'"
      :title="$t('APMA.publishActionPlan')"
    >
      <template #modalContent>
        <p>{{ $t("APMA.modalPublish") }}</p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click.prevent="onPublishAPMA">
              {{ $t("APMA.publish") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Button from "../helper/Button.vue";
import ContentTemplate from "./contentTemplate.vue";
import tableMixins from "@/mixins/tableMixins";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate, Button },
  mixins: [tableMixins, form],
  data() {
    return {
      dialogExpertContact: false,
      dialogPublish: false,
      selected_row: [],
      columns: [
        {
          label: "No",
          isSortable: false,
          fieldName: "index",
          class: "r-table-firstColumn",
        },
        {
          label: "APMA.cyclePhase",
          isSortable: true,
          fieldName: "cycle_phase_name",
          class: "",
        },
        {
          label: "APMA.measure",
          fieldName: "measure",
          isSortable: true,
          class: "",
        },
        {
          label: "Detail",
          fieldName: "detail",
          isSortable: true,
          class: "",
        },
        {
          label: "APMA.priority",
          fieldName: "priority_name",
          isSortable: true,
          class: "",
        },
        {
          label: "Status",
          fieldName: "status",
          isSortable: true,
          class: "",
        },
        {
          label: "general.action",
          isSortable: false,
          fieldName: "action",
          class: "r-table-actionColumn",
        },
      ],
      current_id: "",
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      rows: "actionplan/getActionPlan",
      role_type: "auth/getRole",
      request: "actionplan/getRequest",
      consultant: "expert/getExpert",
      user_data: "auth/getUser",
      modal: "getModal",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    modal() {
      return this.$store.getters["getModal"];
    },
  },
  methods: {
    ...mapActions({
      setActionPlan: "actionplan/setActionPlanData",
      setModal: "setModal",
      delete_actionplan: "actionplan/onDelete",
      setConsultant: "expert/setConsultantData",
      onPublish: "actionplan/onPublish",
    }),

    async initData() {
      await this.getData();
    },
    async onPublishAPMA() {
      await this.onPublish(this.current_id);
      this.setModal(false);
      this.dialogPublish = false;
      this.setActionPlan();
    },
    async onDelete() {
      await this.delete_actionplan(this.current_id);
      this.setModal(false);
      this.setActionPlan();
    },

    setFile(data) {
      this.$router.push(
        this.$translate({
          name: "APMA File",
          params: { id: data.id },
        })
      );
    },

    moveToForm() {
      this.$router.push(
        this.$translate({
          name: "New APMA Assign Task",
          params: { inputType: "New" },
        })
      );
    },

    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setActionPlan();
      this.fetchData = false;
      // const response = this.getResponse("BRRD");
      // if (response.status !== 1) {
      //   this.showSnackbar({
      //     type: "error",
      //     text: response.msg,
      //   });
      // }
    },

    setDetail(data, routeName) {
      this.$router.push(
        this.$translate({
          name: routeName,
          params: { id: data.id },
        })
      );
    },

    setPublish(data, modal) {
      this.current_id = data.id;
      this.setModal(modal);
    },

    setUpdate(data) {
      this.$router.push(
        this.$translate({
          name: "Edit APMA",
          params: { id: data.id, inputType: "Edit" },
        })
      );
    },

    setForum(data) {
      this.$router.push(
        this.$translate({
          name: "APMA Comment",
          params: { room_id: data.room_id },
        })
      );
    },

    openDialog(state, data) {
      this.setModal(state);
      this.current_id = data.id;
      this.selected_row = data;
      this.setConsultant(data.id);
    },
  },
};
</script>
